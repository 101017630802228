import React from 'react';
import { SimpleTabs } from 'components';
import { GeneralReports, ClientReports, BillingReports, StaffReports, PayrollReports, SignatureReports, tabsLabels,  } from './fragments';

export const Report = ({}) => {
    const tabsContent = [
        {
            tabComponent: (
                <GeneralReports />
            ),
            tab: 'Generated Reports',
        },
        // {
        //     tabComponent: (
        //         <ClientReports />
        //     ),
        //     tab: 'Clients',
        // },
        {
            tabComponent: (
                <StaffReports />
            ),
            tab: 'Staff',
        },
        {
            tabComponent: (
                <BillingReports />
            ),
            tab: 'Billing',
        },
        {
            tabComponent: (
                <PayrollReports />
            ),
            tab: 'Payroll',
        },
        {
            tabComponent: (
                <SignatureReports />
            ),
            tab: 'Signature',
        },
    ];

    return (

        <div className="system-item-wrapper">
            <SimpleTabs
                // defaultTab={'Signature'}
                defaultTab={'Generated Reports'}
                tabsLabels={tabsLabels}
                tabsContent={tabsContent}
            />
        </div>

    );
};