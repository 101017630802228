export const getMenuTitle = (url = '') => {
    if (url.startsWith('/fundingSource/')) return 'detail';
    if (url.startsWith('/staff/')) return 'detail';
    if (url.startsWith('/client/')) return 'detail';
    if (url.startsWith('/bill/')) return 'detail';
    if (url.startsWith('/claim/')) return 'detail';
    if (url.startsWith('/billedClaim/')) return 'detail';
    if (url.startsWith('/invoice/')) return 'detail';
    if (url.startsWith('/claimPayment/')) return 'detail';
    if (url.startsWith('/invoicePayment/')) return 'detail';
    if (url.startsWith('/generateClaim')) return 'detail';
    if (url.startsWith('/generateInvoice')) return 'detail';
    if (url.startsWith('/timesheet')) return 'detail';
    if (url.startsWith('/processed/')) return 'detail';
    if (url.startsWith('/help-center')) return 'detail';

    switch (url) {
        case '/fundingSource':
            return 'Funding Source';
        case '/staff':
            return 'Staff';
        case '/client':
            return 'Client';
        case '/management':
            return 'Access Management';
        case '/schedule':
            return 'Schedule';
        case '/activePayroll':
            return 'Payroll';
        case '/processedPayroll':
            return 'Payroll';
        case '/appeals':
            return 'Bills';
        case '/generateBill':
            return 'Bills';
        case '/pendingClaims':
        case '/generateClaim':
        case '/billedClaims':
            return 'Billing';
        case '/invoices':
        case '/generateInvoice':
            return 'Invoices';
        case '/claimPayments':
            return 'Claim Payments';
        case '/report':
            return 'Reports';
        case '/system':
            return 'System';

        default:
            return '';
    }
};
